<template>
    <v-dialog v-model="appCharts.open_disponibilidade_resumida" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <div class="status_mchine">
                <v-card class="mx-2">
                    <v-toolbar color="primary">
                        <v-btn icon="mdi-close" @click="appCharts.open_disponibilidade_resumida = false"></v-btn>
                    </v-toolbar>
                    <v-row class="mt-2">
                        <v-col cols="12" md="4" class="pa-1 pl-md-4">
                            <v-card class="text-start d-flex rounded pa-3 mb-4" color="#4CAF50" dark>
                                <v-row justify="space-between" align="center">
                                    <v-col>
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>PRODUÇÃO</strong>
                                        </h4>
                                    </v-col>
                                    <v-col class="text-right">
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>{{ resourceStatusFabrica.trabalhando.length }}</strong>
                                        </h4>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1 pl-md-2">
                            <v-card class="text-start d-flex rounded pa-3" color="#C62828" dark>
                                <v-row justify="space-between" align="center">
                                    <v-col>
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>PARADA</strong>
                                        </h4>
                                    </v-col>
                                    <v-col class="text-right">
                                        <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                            <strong>{{ resourceStatusFabrica.parada.length }}</strong>
                                        </h4>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4" class="pa-1 py-2 px-2 mt-1 justify-space-between">
                            <v-row>
                                <v-col cols="4" md="4" class="pa-1">
                                    <v-card class="d-flex align-center rounded pa-3" color="#FB8C00" dark>
                                        <v-row justify="space-between" align="center">
                                            <v-col>
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <strong>SETUP</strong>
                                                </h4>
                                            </v-col>
                                            <v-col class="text-right">
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <strong>{{ resourceStatusFabrica.setup.length }}</strong>
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>

                                <v-col cols="4" md="4" class="pa-1">
                                    <v-card class="d-flex align-center rounded pa-3" color="#2979FF" dark>
                                        <v-row justify="space-between" align="center">
                                            <v-col>
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <small>MANUTENÇÃO</small>
                                                </h4>
                                            </v-col>
                                            <v-col class="text-right">
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em;">
                                                    <strong>{{ resourceStatusFabrica.manutencao.length }}</strong>
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>

                                <v-col cols="4" md="4" class="pa-1">
                                    <v-card class="d-flex align-center rounded pa-3 mr-md-5" color="#9E9E9E" dark>
                                        <v-row justify="space-between" align="center">
                                            <v-col>
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em; height:25px;">
                                                    <small>DESLIGADA</small>
                                                </h4>
                                            </v-col>
                                            <v-col class="text-right">
                                                <h4 class="mb-0" style="color: #FFFFFF; font-size: 1.5em;">
                                                    <strong>{{ resourceStatusFabrica.offline.length }}</strong>
                                                </h4>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <v-row class="mt-0">
                <v-col cols="12" sm="4" style="height: 100vh;">
                    <v-card class="ml-2" style="width: 101%; height: 100%; background-color: grey">
                        <v-row justify="start;">
                            <v-col cols="12" v-for="machine in resourceStatusFabrica.trabalhando" :key="machine.name">
                                <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px; padding-right: 16px"
                                    color="#4CAF50">
                                    <v-col cols="1" style="background-color: #388E3C;"
                                        class="d-flex justify-center align-center">
                                        <v-icon icon="mdi-lan-connect" size="40"></v-icon>
                                    </v-col>
                                    <v-col col="10">
                                        <v-row>
                                            <v-col>
                                                <v-row class="d-flex flex-column align-start">
                                                    <small class="ml-4"
                                                        style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                                                        {{ machine.name }}
                                                    </small>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                                            <v-col cols="12">
                                                <v-card-title v-if="machine.op"
                                                    class="d-flex justify-start align-end white--text font-weight-bold text-uppercase"
                                                    style="height: 90px; width: 115%;">
                                                    <strong class="text-h5" style="font-size: 1.5em;">ORDEM DE PRODUÇÃO:
                                                        {{
                                                            machine.op }}</strong>
                                                </v-card-title>
                                                <v-alert v-else type="warning" title="Atenção"
                                                    style="height: 90px;width: 115%;">
                                                    <small
                                                        style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                                                        O.P NÃO INFORMADA
                                                    </small>
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                        <div class="d-flex flex-column align-end">
                                            <small
                                                style="font-size: 1.2em; font-weight: bold; text-transform: uppercase;">
                                                (time_formatado)
                                            </small>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="4" style="height: 100vh">
                    <v-card style="width: 101%; height: 100%; background-color: grey">
                        <v-row justify="start;">
                            <v-col cols="12" v-for="machine in resourceStatusFabrica.parada" :key="machine.name">
                                <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px; padding-right: 16px"
                                    color="#C62828">
                                    <v-col cols="1" style="background-color: #BF360C;"
                                        class="d-flex justify-center align-center">
                                        <v-icon icon="mdi-lan-connect" size="40"></v-icon>
                                    </v-col>
                                    <v-col col="10">
                                        <v-row>
                                            <v-col>
                                                <v-row class="d-flex flex-column align-start">
                                                    <small class="ml-4"
                                                        style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                                                        {{ machine.name }}
                                                    </small>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                                            <v-col cols="12">
                                                <v-card-title v-if="machine.motivo_de_parada"
                                                    class="d-flex justify-start align-end white--text font-weight-bold text-uppercase"
                                                    style="height: 90px; width: 115%;">
                                                    <strong class="text-h5" style="font-size: 1.5em;">MOTIVO DE PARADA:
                                                        {{ machine.motivo_de_parada }}</strong>
                                                </v-card-title>
                                                <v-alert v-else type="warning" title="Atenção"
                                                    style="height: 90px; width: 115%; color: #EF5350;">
                                                    <small
                                                        style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                                                        M.P NÃO INFORMADA
                                                    </small>
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                        <div class="d-flex flex-column align-end">
                                            <small
                                                style="font-size: 1.2em; font-weight: bold; text-transform: uppercase;">
                                                (time_formatado)
                                            </small>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="4" class="pl-1" style="height: 100vh">
                    <v-card class="mr-2" style="height: 100%; background-color: grey">
                        <v-row justify="start">
                            <v-col cols="12" v-for="machine in resourceStatusFabrica.offline" :key="machine.name">
                                <v-card class="d-flex mt-2 ml-2 mr-2" style="height: 150px; padding-right: 16px"
                                    color="#2B353B">
                                    <v-col cols="1" style="background-color: #3C464E"
                                        class="d-flex justify-center align-center">
                                        <v-icon icon="mdi-lan-disconnect" size="40"></v-icon>
                                    </v-col>
                                    <v-col col="10">
                                        <v-row>
                                            <v-col>
                                                <v-row class="d-flex flex-column align-start">
                                                    <small class="ml-4"
                                                        style="font-size: 1.5em; font-weight: bold; text-transform: uppercase;">
                                                        {{ machine.name }}
                                                    </small>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1">
                                        <div class="d-flex flex-column align-end">
                                            <small
                                                style="font-size: 1.2em; font-weight: bold; text-transform: uppercase;">
                                                (time_formatado)
                                            </small>
                                        </div>
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';

import { useAppMaquinas } from '../../../stores/appMaquinas';
export default {
    name: "charts_recursos_por_status",
    props: { apontamento: Object },
    data() {
        return {
            dialog: true,
            producao: [],
            parada: [],
            setup_manutencao_offline: [],

        }
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        resourceStatusFabrica() {
            const statusFabrica = {
                trabalhando: [],
                parada: [],
                setup: [],
                manutencao: [],
                offline: [],
            };



            useAppMaquinas().resourceMaquinas.forEach(recurso => {
                const machine = {
                    name: recurso.nome,
                    op: recurso.ordem_de_producao,
                    motivo_de_parada: recurso.apontamentos.map((apt) => apt.desc_motivo_parada),
                };
                console.log("marlon sdfsdfsdf", machine)

                const apontamento_aberto = {
                    manutencao: null,
                    setup: null
                }

                recurso.apontamentos.filter(apontamento => apontamento.status === 'Aberto').forEach(apontamento => {
                    apontamento_aberto.manutencao = apontamento.manutencao;
                    apontamento_aberto.setup = apontamento.setup;
                    machine.op = apontamento.ordem_de_producao;
                    machine.motivo_de_parada = apontamento.desc_motivo_parada;
                });

                if (recurso.state_sensor === 'OFFLINE') {
                    statusFabrica.offline.push(machine);

                } else if (recurso.state_sensor === 'ACTIVE') {
                    statusFabrica.trabalhando.push(machine);

                } else if (recurso.state_sensor === 'IDLE') {
                    if (apontamento_aberto.manutencao == 1) {
                        statusFabrica.manutencao.push(machine);
                    } else if (apontamento_aberto.setup == 1) {
                        statusFabrica.setup.push(machine);
                    }
                    else {
                        statusFabrica.parada.push(machine);
                    }
                }
            });

            return statusFabrica;
        }

    },
    async mounted() {
        await useAppCharts().status_atual_fabrica()
    },
    methods: {}
};
</script>
</script>

<style scoped>
.pa-1 {
    padding: 3%;
}

.pa-2 {
    padding: 2%;
}

.pa-3 {
    padding: 16%;
}
</style>
