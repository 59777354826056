<template>
    <v-dialog v-model="appApontamento.showModalObs" persistent>
        <v-toolbar color="primary">
            <v-btn @click="appApontamento.showModalObs = false" color="secondary" variant="elevated"
                prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>
            <v-toolbar-title>OBSERVAÇÃO</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-row>
                <v-col cols="12">
                    <v-textarea class="mt-1 pl-1 pr-1 pt-1 pb-0" variant="outlined" color="primary"
                        v-model="appApontamento.observacao_modal" rows="5"></v-textarea>
                </v-col>
            </v-row>
            <v-row justify="end" class="mb-0 mt-0 pt-0 pb-0">
                <v-col cols="3" class="pr-0 pl-2 mb-2">
                    <v-btn size="large" style="width: 100%;" @click="onSaveObs" color="primary">CONFIRMAR</v-btn>
                </v-col>
                <v-col cols="3" class="mr-3" >
                    <v-btn size="large" style="width: 100%;" @click="oncancelObs" color="#F44336">CANCELAR</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppApontamento } from '../../../stores/appApontamento';

import { useAppStore } from '../../../stores/app';

export default {
    name: "observacao",
    data() {
        return {
        }
    },
    computed: {
        appApontamento() {
            return useAppApontamento();
        },
        appStore() {
            return useAppStore();
        }
    },
    watch: {

    },
    methods: {
        async onSaveObs() {
            if (!useAppStore().view_apontamento_historico) {
                useAppStore().apontamento_atual.observacao = useAppApontamento().observacao_modal
            }
            else {
                useAppApontamento().resourceApontamento.observacao = useAppApontamento().observacao_modal
            }
            useAppApontamento().showModalObs = false;
            const json_update = {
                observacao : useAppApontamento().observacao_modal
            }
            if (!useAppStore().view_apontamento_historico) {
                useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, json_update)
            }
            else {
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, json_update)
            }    
                   
            useAppApontamento().observacao_modal = null


        },
        oncancelObs() {
            useAppApontamento().observacao_modal = null
            useAppApontamento().showModalObs = false;
        }
    }
};

</script>


<style></style>